// src/pages/RomanEmpireMapComponent.js

import React from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import './RomanEmpireMapComponent.css';

// Define your custom GeoJSON Polygon
const romanEmpireGeoJSON = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "Roman Empire"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [11.510662832268963, 43.285879400624964],
            [12.659130424328282, 41.15170523961686],
            [13.591367869574697, 43.492888397358314],
            [11.962221939628506, 45.412201723887506],
            [10.928662724771186, 44.12244581575868],
            [11.510662832268963, 43.285879400624964]
          ]
        ]
      }
    }
  ]
};

// Define styles to make the overlay prominent
const geoJsonStyle = {
  color: 'red',             // Bright outline color
  weight: 3,                // Thicker border
  fillOpacity: 0.3,         // Semi-transparent fill
  fillColor: 'purple'       // Fill color to make it stand out
};

const RomanEmpireMapComponent = () => {
  // Function to bind popups to each feature
  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.name) {
      layer.bindPopup(`<strong>${feature.properties.name}</strong>`);
    }
  };

  return (
    <div className="map-container">
      <MapContainer
        center={[41.9028, 12.4964]} // Centered on Rome, Italy
        zoom={5}                     // Adjusted zoom level for better visibility
        className="leaflet-map"
      >
        <TileLayer
          attribution="&copy; OpenStreetMap contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoJSON
          data={romanEmpireGeoJSON}
          style={geoJsonStyle}
          onEachFeature={onEachFeature}
        />
      </MapContainer>
    </div>
  );
};

export default RomanEmpireMapComponent;
