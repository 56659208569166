import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import './App.css';

// Importing Page Components
import Home from './pages/Home';
import GreekRomanMythology from './pages/GreekRomanMythology';
import MonarchsUK from './pages/MonarchsUK';
import RomanEmpireComponent from './pages/RomanEmpireComponent';
import ByzantineEmpireComponent from './pages/ByzantineEmpireComponent';
import RomanEmpireMapComponent from './pages/RomanEmpireMapComponent'; // Import the Map Component

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navigation Menu */}
        <nav className="App-nav">
          <ul>
            <li>
              <NavLink to="/" end className={({ isActive }) => isActive ? 'active' : undefined}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/mythology" className={({ isActive }) => isActive ? 'active' : undefined}>
                Greek & Roman Mythology
              </NavLink>
            </li>
            <li className="dropdown">
              <button type="button">
                Empires
              </button>
              <div className="dropdown-content">
                <NavLink to="/empires/roman" className={({ isActive }) => isActive ? 'active' : undefined}>
                  The Roman Empire
                </NavLink>
                <NavLink to="/empires/byzantine" className={({ isActive }) => isActive ? 'active' : undefined}>
                  The Byzantine Empire
                </NavLink>
                <NavLink to="/empires/roman/map" className={({ isActive }) => isActive ? 'active' : undefined}>
                  Roman Empire Map
                </NavLink>
              </div>
            </li>
            <li>
              <NavLink to="/monarchs-uk" className={({ isActive }) => isActive ? 'active' : undefined}>
                The Monarchs of the UK
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* Page Content */}
        <div className="App-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mythology" element={<GreekRomanMythology />} />
            <Route path="/empires/roman" element={<RomanEmpireComponent />} />
            <Route path="/empires/roman/map" element={<RomanEmpireMapComponent />} /> {/* Add Map Route */}
            <Route path="/empires/byzantine" element={<ByzantineEmpireComponent />} />
            <Route path="/monarchs-uk" element={<MonarchsUK />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
