import React from 'react';
import './RomanEmpireComponent.css';
import RomanEmpireMap from '../assets/RomanEmpireMap.jpg'; // Import the image

function RomanEmpireComponent() {
  return (
    <div className="RomanEmpire">
      <h2>The Roman Empire</h2>
      <p>
        Invading at its height, the Roman Empire controlled territories across Europe, North Africa, and the Middle East. Important places included modern-day Italy, Spain, France, Greece, Egypt, and parts of the British Isles and the Middle East.
      </p>
      <p>
        The empire expanded through military conquest. The empire was ruled by emperors who had supreme power. They were seen as the good military, political, and religious people.
      </p>
      <h3>Senate</h3>
      <p>
        While the Senate had lost lots of its power compared to the Roman Republic, it was still a major factor of power. The Emperor still held the majority of its power. He normally consulted them for small things, but the Emperor made the major decisions, such as deciding whom to wage war on.
      </p>
      <h3>Provinces</h3>
      <div className="ProvincesSection">
        <p>
          The empire was divided into provinces, each governed by a Roman-appointed official or governor.
        </p>
        <img src={RomanEmpireMap} alt="Roman Empire Map" className="RomanEmpireMap" />
      </div>
      <p>
        Roman law laid the foundation for many legal systems still in use today.
      </p>
      <p>
        Romans were great builders known for their roads, aqueducts, amphitheaters like the Colosseum, and monumental structures. They were the first to use brick and stone.
      </p>
      <p>
        Latin was the official language of the empire; Greek was also spoken in the east.
      </p>
      <p>
        Roman religion was worshipping a pantheon of gods and goddesses like Jupiter, Mars, and Venus. In 313 CE, Emperor Constantine made Christianity official.
      </p>
      <p>
        The Roman military was highly organized with legions being the main part of the army. Soldiers were well-trained and military tactics were good for the time.
      </p>
      <p>
        The army played an important role in the expansion and defense of the empire.
      </p>
      <p>
        The Roman economy was diverse and included agriculture, trade, mining, and manufacturing. The empire’s extensive road and sea networks facilitated trade between regions.
      </p>
      <p>
        Slavery was an important part of the economy, with slaves working in houses.
      </p>
      <p>
        Roman coins were widely used across the Empire.
      </p>
      <h3>Fall of Rome</h3>
      <p>
        The Western Roman Empire fell in 476 CE when the last emperor was deposed. Reasons contributing to the fall included internal issues, economic troubles, and army defeats by barbarians.
      </p>
      <p>
        The Eastern Roman Empire, known as the Byzantine Empire, survived for nearly a thousand years, ending in 1453 CE with its capital in Istanbul.
      </p>
      <p>
        The Roman Empire left a legacy in Western civilization, influencing government, law, architecture, language, building, and religion. It officially began in 27 BCE and split in 395 CE.
      </p>
    </div>
  );
}

export default RomanEmpireComponent;
